<template>
    <div>
        <a 
            :href="`https://stashh.io/asset/${collectionId}/${tokenId}`" 
            target="_blank"
            class="stash-link"
        >
            <img 
                src="@/assets/icons/stashh.svg" 
                alt="stash icon">
            <span> View on Stashh marketplace </span>
            <img 
                src="@/assets/icons/external-link.svg" 
                alt="external link icon"
            >
        </a>
    </div>
  
</template>

<script>
export default {
    name: 'StashhLink',
    props: {
      collectionId : {
        type: String,
        required: true,
      },
      tokenId : {
        type: String,
        required: true
      }  
    },
    data() { return {} }
}
</script>

<style lang="scss" scoped>
a {
    text-decoration: none;
    &.stash-link {
        display: flex;
        column-gap: 8px;
        span {
            line-height: 24px;
            color: var(--color-emerald);
        }
    }
}
</style>
