<template>
  <div v-if="!isLoading" :class="`free__mint__flag ${variant}`">
     <span> {{ freeMintState }}</span>
  </div>
</template>

<script>
import { stringIdToNumber, initContracts } from "@/utils";
import { refContract } from '@stakeordie/griptape.js';

let tokensLimit = stringIdToNumber(process.env.VUE_APP_STUDIES_ID_LIMIT) + 1;

export default {
  props: {
    tokenId: {
      type: String,
      required: true,
    },
    contractAddress: {
        type: String, 
        required: true,
    },
    mintingContractAddress : {
        type: String,
        required: true,
    }
  },
  data() {
    return {
      variant: "",
      freeMintState: "",
      redemeed: [],
      elegibleTokens: [],
      notElegibleTokens: [],
      deadStock: 210,
      isLoading: false,
    };
  },
  async mounted() {
    await initContracts();
    await this.getRedemeedTokens();
    await this.validateTokenEligibility();
    this.setFreeMintState(this.tokenId);
  },
  methods: {
    async getRedemeedTokens() {
      this.isLoading = true;
      const mintingContract = refContract(this.mintingContractAddress);
      try {
        const { list } = await mintingContract.approvedStudies();
        this.redemeed = list.map((token) => stringIdToNumber(token));
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async validateTokenEligibility() {
      this.isLoading = true;
      const snip721ContractAddress = refContract(this.contractAddress);
      try {
        const response = await snip721ContractAddress.getAllTokens();
        const tokens = response.token_list.tokens;
        if (tokens.length > 0) {
          const idNumber = tokens.map((token) => stringIdToNumber(token));
          this.elegibleTokens = idNumber.filter((token) => token < tokensLimit);
          this.notElegibleTokens = idNumber.filter(
            (token) => token >= tokensLimit + this.deadStock
          );
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    setFreeMintState(tokenId) {
      if (!tokenId) return;

      tokenId = stringIdToNumber(tokenId);

      const wasRedeemed = this.redemeed.includes(tokenId);
      const isElegible = this.elegibleTokens.includes(tokenId);
      const noElegible = this.notElegibleTokens.includes(tokenId);

      if (isElegible && !wasRedeemed) {
        this.freeMintState = `This Big Bang is eligible to redeem for a free mint.`;
        this.variant = "success";
      } else if (isElegible && wasRedeemed) {
        this.freeMintState = `This Big Bang's free mint has already been redeemed.`;
        this.variant = "warning";
      } else if (noElegible) {
        this.freeMintState = `This Big Bang is not eligible for a free mint.`;
        this.variant = "warning";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.free__mint__flag {
  padding: 7px 10px;
  width: 100%;
  column-gap: 10px;
  span {
    color: var(--color-neutral-white);
    line-height: 24px;
    font-size: 16px;
  }
  &.success {
    background-color: #589e5f;
    span {
        color: var(--color-neutral-white);
    }
  }
  &.warning {
    background-color: var(--color-yellow);
    span {
      color: black;
    }
  }
  &.danger {
    background-color: var(--color-starter-red-02);
  }
}
</style>
