<template>
        <div class="nft-details">
            <div class="project" v-if="project && art">
                <div class="project__left box">
                    <div class="autor">
                        <h1>{{ project.title }} #{{ art.id % 1000000 }}</h1>
                        <h4>by {{ project.author }}</h4>
                        <div class="social-media">
                            <img class="social-media-logo" src="@/assets/icons/logo-twitter.svg" alt="twitter">
                            <a 
                                :href="author.website"
                                class="link"
                                v-for="(author, index) in projectAuthors"
                                :key="index"
                            >
                                {{ author.username }}
                            </a>
                        </div>
                    </div>

                    <div class="image-block-mobile">
                        <div class="card" v-if="art">
                            <div v-if="art.previewImageUrl">
                                <img :src=art.previewImageUrl :alt=art.id>
                            </div>
                            <div v-else >
                                <img 
                                    :src="`${imagesUrl}/projects/${art.projectId}/placeholder_ungenerated.png`"
                                    :alt=art.id
                                >
                            </div>
                            <div class="links" v-if="!isOwner">
                                <div class="links-left">
                                    <a target="_blank" :href="art.previewImageUrl">image</a>
                                    <router-link target="_blank" v-if="isOwner" :to="{ name: 'live', params: { projectId: projectId, artId: art.id } }">live</router-link>
                                </div>
                                <div class="links-right">
                                    <router-link :to="{ name: 'gallery', params: { projectId: projectId }}">view entire gallery</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="links" v-if="isOwner && isArtGenerated">
                        <a target="_blank" :href="art.previewImageUrl">image</a>
                        <router-link target="_blank" :to="{ name: 'live', params: { projectId: projectId, artId: art.id } }">live</router-link>
                        <router-link :to="{ name: 'gallery', params: { projectId: projectId }}">view entire gallery</router-link>
                    </div>

                    <div class="dates">
                        <p>
                            Date Minted: <span class="date">{{ project.startDate | long-date }}</span>
                        </p>
                        <p>
                            Mint Price: <span class="date">{{project.pricePerToken}} sSCRT</span>
                        </p>
                        <p>
                            Copyright: <span class="date">{{project.copyRight}}</span>
                        </p>
                        <p>
                            Script: <span class="date">{{project.scriptType}}</span>
                        </p>

                        <a 
                            v-if="isOwner && art.projectId === '2'" 
                            class="twitter-share-button"
                            :href="`https://twitter.com/intent/tweet?text=I just supported Ukraine humanitarian efforts by minting RESILIENT at https://emprops.io 🇺🇦🌻%0D%0A%0D%0AI challenge @XXX , @XXX and @XXX to join me and mint RESILIENT too!%0D%0A%0D%0A@emprops_io %23ResilientUkraineChallenge`"
                            data-size="large"
                        >
                            Tweet
                        </a>

                        <stashh-link v-if="isArtGenerated"
                            collectionId= "secret14j66mdlnp2v5w7wutcnsa4ddmplsyk2uav2fnl"
                            :tokenId="art.id"
                        >
                        </stashh-link>
                        <free-mint-flag
                            v-if="art.projectId == '1'"
                            :token-id="art.id"
                            :contract-address='project.snip721ContractAddress'
                            :minting-contract-address="project.mintingContractAddress"
                        >
                        
                        </free-mint-flag>
                    </div>

                    <accordion title="description" class="description">
                        <template slot="content">
                            <description v-if="projectId == '1'"> </description>
                            <home-description v-else> </home-description>
                        </template>
                    </accordion>
                    
                    <div class="traits" v-if="isArtGenerated && projectId == '1' ">
                        <accordion title="traits">
                            <template slot=content>
                                <div>
                                    <label class="labels warning" v-if="!this.areTraitsReveal && !this.isOwner && !this.updateTraitsLoading">
                                        The owner has not revealed this token's traits yet.
                                    </label> 
                                    <div 
                                        class="reveal-message"
                                        v-if="isOwner && !this.updateTraitsLoading"  
                                    >
                                        <div v-if="!areTraitsReveal">Rarity traits require non-public data to be calculated
                                            (the token's seed will remain private).
                                        </div>
                                        <div v-else>Traits published.</div>

                                        <div
                                            v-if="!areTraitsReveal"
                                            class="label"
                                            @click="onUpdateTraits"
                                        >
                                            Publish traits
                                        </div>
                                    </div>
                                </div>
                                <loading v-if="this.updateTraitsLoading" text="Revealing traits" /> 
                                <table class="traits-table">
                                    <thead>
                                        <tr>
                                            <td class="title">Trait</td>
                                            <td class="title center">Value</td>
                                            <td class="title end">Rarity*</td>
                                        </tr>
                                    </thead>
                                    <tbody v-bind:key="propertyName" v-for="(value, propertyName) in traits">
                                        <tr @click="goToTraits( projectId, art.id, propertyName, value )">
                                            <td>{{ propertyName }}</td>
                                            <td class="center" v-if="isTraitValid(propertyName)">{{ value ? getTraitValue(propertyName) : '-' }}</td>
                                            <td class="center" v-else>
                                                <vue-custom-tooltip class="custom-tooltip" :active="true" label="This trait has been tampered by the user.">
                                                    <span>{{ value ? getTraitValue(propertyName) : '-' }}</span>
                                                    <img src="@/assets/icons/wrong-trait.svg" alt="">
                                                </vue-custom-tooltip> 
                                            </td>
                                            <td class="end">
                                                <p>
                                                    {{getRarityByNameAndValue(propertyName,value)}}
                                                    
                                                </p>
                                                <span>
                                                    {{getRarityCountByNameAndValue(propertyName,value)}}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="traits-table-description">
                                    <p>
                                        * Rarity percentages are dependent on owners
                                        revealing their tokens' traits, and
                                        therefore have not yet been fully
                                        calculated. Please check back later for an
                                        update.
                                    </p>
                                </div>
                            </template>
                        </accordion>
                    </div>
                    
                    <div class="traits" v-else>
                        <accordion title="traits">
                            <template slot=content>
                                <table class="traits-table">
                                    <thead>
                                        <tr>
                                            <td class="title">Trait</td>
                                            <td class="title center">Value</td>
                                            <td class="title end">Rarity*</td>
                                        </tr>
                                    </thead>
                                    <tbody v-for=" (trait, index) in onChainTraits" :key="index">
                                        <tr @click="goToTraits( projectId, art.id, trait.trait_type, trait.value )">
                                            <td>{{ trait.trait_type }}</td>
                                            <td class="center">{{ trait.value }}</td>
                                            <td class="end">
                                                <p>
                                                    {{getRarityByNameAndValue(trait.trait_type, trait.value)}}
                                                </p>
                                                <span>
                                                    {{getRarityCountByNameAndValue(trait.trait_type, trait.value)}}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="traits-table-description">
                                    <p>
                                        * Rarity percentages are dependent on owners
                                        revealing their tokens' traits, and
                                        therefore have not yet been fully
                                        calculated. Please check back later for an
                                        update.
                                    </p>
                                </div>
                            </template>
                        </accordion>
                    </div>
                </div>

                <div class="project__right box">
                    <div class="image-block-desktop">
                        <div class="card" v-if="art">
                            <div v-if="art.previewImageUrl">
                                <img :src=art.previewImageUrl :alt=art.id>
                            </div>
                            <div v-else >
                                <img 
                                    :src="`${imagesUrl}/projects/${art.projectId}/placeholder_ungenerated.png`" 
                                    :alt=art.id
                                >
                            </div>
                            <div class="links" v-if="!isOwner">
                                <div class="links-left">
                                    <a v-if="art.previewImageUrl" target="_blank" :href="art.previewImageUrl">image</a>
                                    <router-link target="_blank" v-if="isOwner" :to="{ name: 'live', params: { projectId: projectId, artId: art.id } }">live</router-link>
                                </div>
                                <div class="links-right">
                                    <router-link :to="{ name: 'gallery', params: { projectId: projectId }}">view entire gallery</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="provenance" v-if="isOwner && isArtGenerated">
                        <div class="provenance-image" v-if="projectId === '1'">
                            <p class="title">
                            Download High-Res Image
                            </p>
                            <div class="labels">
                                <label>Width in pixels</label> 
                                <span class="note">(most computers support 5000px, but we are working on a plan to increase this)</span>
                            </div>
                            <div class="image-input">
                            <input 
                                type="text" 
                                v-model="sizePicture" 
                                max="20000" 
                                maxlength="5"
                            />
                            <a 
                                :href="`/projects/${projectId}/art/${art.id}/live?size=${sizePicture}`"
                                target="_blank"
                            >
                                <img src="@/assets/icons/download.png" :alt="'img download'"> 
                            </a>
                            </div>
                        </div>

                        <div class="provenance-records">
                            <span v-show="showSuccessMessage" class="success-message"> {{ successMessage }} </span>
                            <p class="title">Private Provenance<span class="label" @click="showProvenanceList = !showProvenanceList">{{showProvenanceList ? 'hide': 'show'}} records</span></p>
                            <div class="record" v-show="showProvenanceList" v-for="(record, index) in provenanceList" :key="index">
                                <p class="name">
                                    {{record.name}} 
                                    <span class="date">{{ moment(record.acquisition_date).format("MMMM DD, YYYY") }}</span>
                                    <span class="price"> {{record.price}} sSCRT </span>  
                                </p>
                            </div>
                            <div v-if="showProvenanceList && provenanceList.length === 0" class="empty">
                                <p>No records available.</p>
                            </div>
                        </div>

                        <div v-if="isloadingProvenance" style="margin-top: 20px">
                            
                        </div>

                        <div class="provenance-inputs" v-else-if="!ownerRegisteredProvenance">
                            <p>Create your Private Provenance record</p>
                            
                            <span v-show="showErrorMessage" class="error-message"> {{ errorMessage }} </span>
                            
                            <div class="field">
                                <label data-end="*">Name (20 characters max)</label> 
                                <input type="text" :class="{'required': authorName}" placeholder="John Doe" maxlength="20" v-model="initials" required/>
                                <label v-show="authorName" class="required-message">This field is required</label>
                            </div>
                            
                            <div class="fields-col">
                                <div class="field">
                                    <label data-end="*">Date of Acquisition*</label> 
                                    <input type="date" :max="today" :class="{'required': provenanceDateFlag}" v-model="provenanceDate" required/>
                                    <label v-show="provenanceDateFlag" class="required-message">This field is required</label>
                                </div>

                                <div class="field">
                                    <label data-end="*">AcquisitionPrice</label> 
                                    <input type="number" min="0" :class="{'required': tokenPrice}" placeholder="sSCRT" v-model="scrtPrice" required/>
                                    <label v-show="tokenPrice" class="required-message">This field is required</label>
                                </div>
                            </div>
                            
                            <button
                                :disabled="disableButton" 
                                @click="sendProvenance()">{{formIsLoading ? 'LOADING...' : 'SAVE' }}
                            </button>
                        </div>
                    </div>

                    <iframe id="iframe" frameborder="0"></iframe>
                </div>
            </div>
        </div>
</template>

<script>
import { getAddress, onAccountAvailable, refContract } from '@stakeordie/griptape.js';
import api from '@/service/api';
import { formatTraitAttribute, initContracts  } from '@/utils';
import moment from 'moment';
import VueCustomTooltip from '@adamdehaven/vue-custom-tooltip'
import Loading from "@/components/Loading";
import Description from '@/components/Description';
import StashhLink from '@/components/TokenDetail/StashhLink.vue';
import FreeMintFlag from '@/components/UiUtils/FreeMintFlag.vue';
import Accordion from '@/components/UiUtils/Accordion.vue';
import Alert from '@/components/UiUtils/Alerts/Alert.vue';
import HomeDescription from '@/components/Home/HomeDescription.vue';

function currentDate() {
    var today = new Date();
    var currentDate = String(today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate());
   
    return currentDate
}

export default {
    
    name: 'nft-details',
    components: { 
        Loading, 
        Description, 
        StashhLink,
        VueCustomTooltip,
        FreeMintFlag,
        Accordion,
        Alert,
        HomeDescription
    },
    data() {
        return {
            traits: {
                "Color Palette": "",
                "Number of Rings": "",
                "Genesis Ring Points": "",
                "Spikiness": "",
                "Density": "",
                "Pattern Fills": "",
                "Pattern": "",
                "Background Pattern": "",
                "Shadows": "",
                "Light Source": "",
                "Chaos": "",
                "Glitch": "",
                "God Mode": ""
            },
            today: currentDate(),
            projectId: '',
            project: null,
            art: null,
            showErrorMessage: false,
            showSuccessMessage: false,
            errorMessage: 'An error occurred, please try again.',
            successMessage: 'Provenance saved successfully',
            isOwner: false,
            nftDossier:{
                public_metadata:{
                    extension:{
                        attributes:[
                            {
                                trait_type:'-',
                                value:'-'
                            },
                            {
                                trait_type:'-',
                                value:'-'
                            },
                            {
                                trait_type:'-',
                                value:'-'
                            }
                        ]
                    }
                }
            },
            sizePicture: '',
            authorName: false,
            provenanceDateFlag: false,
            areTraitsReveal: false,
            initials: '',
            provenanceDate: null,
            scrtPrice: '',
            tokenPrice: false,
            provenanceFirstTime: true,
            showProvenanceList: false,
            address: '',
            provenanceList: [],
            formIsLoading: false,
            updateTraitsLoading: false,
            moment: moment,
            dicTraits : {
                mode: "God Mode",
                densityOfRings: "Density",
                chaotic: "Chaos",
                numberOfRings: "Number Of Rings",
                pointsNumber: "Genesis Ring Points",
                spikiness: "Spikiness",
                colorPaletteName: "Color Palette",
                hasPatterns: "Pattern Fills",
                pattern: "Pattern",
                backgroundPattern: "Background Pattern",
                hasDropShadows: "Shadows",
                lightSource: "Light Source",
                glitch: "Glitch"
            },
            isloadingProvenance: false,
            realTraits:[],
            onChainTraits:[],
            rarities:{ },
            showDescription: false,
            imagesUrl: process.env.VUE_APP_IMAGES_URL
        }
    },
    filters: {
        capitalize: function (value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        rarityFilter: function (value) {
            if(!value || isNaN(value)) return '?';

            return `${value} %`
        }
    },
    async mounted() {
        await this.getProject();
        await initContracts();
        await this.getArt();
        await this.getRarity();
        onAccountAvailable(async () => {
            this.address = getAddress();
            await this.getOwnerOf();
            await this.getProvenanceRecords();
            await this.getNftDossier();
            if(this.projectId == '1' ){
                await this.updateLocalTraits();
                await this.getRealTraits();
                if(this.isOwner){
                    await this.saveTraitsByOwner();
                }
            }
        });
    },
    computed: {
        disableButton() {
          return !this.initials || !this.provenanceDate || !this.scrtPrice;
        },
        ownerRegisteredProvenance() {
            if(!this.provenanceList || this.provenanceList.length < 1) return false;

            return this.provenanceList[this.provenanceList.length - 1].owner === this.address;
        },
        isArtGenerated() {
            return this.art.status == 'GENERATED';
        },
        projectAuthors(){
            return this.project.authorWebsite.split(",").map((res) => {
                return {
                    website: res,
                    username: "@" + res.slice(20),
                }
            });
        }
    },
    methods: {
        async getProject() {
            this.projectId = this.$route.params.projectId;
            this.project = await api.projects.get(this.$route.params.projectId);
        },
        getRarityByNameAndValue(name,value) {
            const trait = this.rarities[name]
            if(!trait) return '?';
            const trait_obj = trait[value]
            if(!trait_obj) return '?';
            const rarity = trait_obj['value']
            if(!rarity) return '?';
            
            return `${rarity.toString()} %`
        },
        getRarityCountByNameAndValue(name, value) {
            const trait = this.rarities[name]
            if(!trait) return '?';
            const trait_obj = trait[value]
            if(!trait_obj) return '?';

            const count = trait_obj['count']
            if(!count) return '?';

            return `(${count.toString()} tokens)`;
        },
        async getArt() {
            this.art = await api.projects.arts.get(this.projectId, this.$route.params.artId);
        },
        async getRarity() {
            this.rarities = await api.projects.arts.traits.rarity.get(this.$route.params.projectId, this.$route.params.artId);
        },
        async getOwnerOf() {
            const snip721Contract = refContract(this.project.snip721ContractAddress);
            try {
                const res = await snip721Contract.getOwnerOf(this.art.id);
                const { owner_of } = res;
                this.isOwner = owner_of.owner === this.address;
            } catch (error) {
                this.isOwner = false;
            }
        },
        changeShowProvenanceList() {
            this.showProvenanceList = !this.showProvenanceList;
        },
        async getProvenanceRecords() {
          this.isloadingProvenance = true;
          const snip721Contract = refContract(this.project.snip721ContractAddress);
          try {
            const res = await snip721Contract.getProvenance(this.art.id);
            const records = res.provenance.list;
            this.provenanceList = records;
          } catch(error) {
            console.warn(error);
          } finally {
            this.isloadingProvenance = false;
          }
        },
        async getNftDossier() {
          try {
            const snip721Contract = refContract(this.project.snip721ContractAddress);
            const res = await snip721Contract.getNftDossier(this.art.id, false, true);
            this.nftDossier = res.nft_dossier;
            this.areTraitsReveal =  this.nftDossier.public_metadata.extension.attributes.length > 3
            this.onChainTraits = this.nftDossier.public_metadata.extension.attributes;
          }catch(error) {
            console.error(error);
          }
        },
        async updateTraits() {
            const snip721Contract = refContract(this.project.snip721ContractAddress);
            try {
                this.updateTraitsLoading = true;
                const local_features = localStorage.getItem(`features_${this.art.id}`);
                let features = JSON.parse(local_features)
                let attributes = Object.keys(features).map((key)=>{
                    return {
                        display_type:null,
                        max_value:null,
                        trait_type:key,
                        value:features[key].toString()
                    } 
                });
                const id = this.art.id - 1000000;
                let public_metadata = this.nftDossier.public_metadata;
                public_metadata.extension.name = `The Big Bang #${id}`
                public_metadata.extension.attributes = attributes
                
                if (public_metadata?.extension?.media?.length !== 0) {
                    public_metadata.extension.media = [{
                     file_type: "image",
                     extension: "png",
                     authentication: null,
                     url: this.art.thumbnailImageUrl,
                    }]
                }
                await this.saveTraits()
                await snip721Contract.setMetadata(this.art.id, public_metadata)
                await this.getNftDossier();
            } catch (error) {
                console.error(error)
            } finally{
                this.updateTraitsLoading = false;
            }
        },
        async onUpdateTraits(){
            this.updateTraitsLoading = true;
            try {
                let local_features = localStorage.getItem(`features_${this.art.id}`);
                if (!local_features) {
                    await this.updateLocalTraits();
                    setTimeout(async()=>{
                        await this.updateTraits();
                    },5000)
                }else {
                    await this.updateTraits();
                }
            } catch (error) {
                console.error("Updating traits error: ", error);
                this.updateTraitsLoading = false;
            }
        },
        getTraitValue(traitName){
            if(!traitName) return;

            const onChainValue = this.onChainTraits.find((ele)=> ele.trait_type == traitName);

            if(!onChainValue) {
                if(!this.traits){
                    return '-'
                } else { 
                    return this.traits[traitName];
                }
            }else {
                return onChainValue.value;
            }

        },
        async updateLocalTraits(){
            const traits = localStorage.getItem(`features_${this.art.id}`);
            const snip721Contract = refContract(this.project.snip721ContractAddress);
            if (!traits) {
                let nft_dossier;
                if (this.isOwner) {
                    let { nft_dossier: result } = await snip721Contract.getNftDossier(this.art.id);
                    nft_dossier = result;
                } else {
                    let { nft_dossier: result } = await snip721Contract.getNftDossier(this.art.id, false, true);
                    nft_dossier = result;
                }

                const { private_metadata, public_metadata, seed } = nft_dossier;

                const metadata = this.areTraitsReveal ? public_metadata : private_metadata;

                const answers = {};
                for (const item of metadata.extension.attributes) {
                    answers[item.trait_type] = item.value;
                }

                if (this.areTraitsReveal) {
                    this.traits = answers;
                    localStorage.setItem(`features_${this.art.id}`, JSON.stringify(answers))
                    return;
                }

                const { code: { code } } = await snip721Contract.getP5jsCode();
                const textToReplace = "<!-- FOR MINTING ONLY, DO NOT MODIFY -->";
                const getTokenInfoScript = `<script>function getTokenInfo(projectNumber) { return { answers: { "Color Palette": '${answers['Color Palette']}', "Number of Rings": ${parseFloat(answers['Number of Rings'])}, "Genesis Ring Points": ${parseFloat(answers['Genesis Ring Points'])} }, hash: '${seed}', tokenId: ${this.art.id}}}<\/script>`;

                // Encode and save the token data and the code in local storage
                const html = this.fromBase64(code).replace(textToReplace, getTokenInfoScript)

                // Load the iframe with the generate-art.html page
                const iframe = document.getElementById('iframe');
                iframe.srcdoc = html;
                setTimeout(async () => {
                    const { getTokenInfo, calculateFeatures } = iframe.contentWindow;
                    const tokenInfo = getTokenInfo();
                    const result = calculateFeatures(tokenInfo);
                    this.traits = result;
                    await this.saveTraits()
                    localStorage.setItem(`features_${this.art.id}`, JSON.stringify(result))
                }, 5000);
            } else {
                this.traits = JSON.parse(traits);
            }
        },
        async saveTraitsByOwner(){
            const snip721Contract = refContract(this.project.snip721ContractAddress);
            try {
                let nft_dossier;
                let { nft_dossier: result } = await snip721Contract.getNftDossier(this.art.id);
                nft_dossier = result;
                
    
                const { private_metadata, public_metadata, seed } = nft_dossier;
    
                const metadata = this.areTraitsReveal ? public_metadata : private_metadata;
    
                const answers = {};
                for (const item of metadata.extension.attributes) {
                    answers[item.trait_type] = item.value;
                }
    
                const { code: { code } } = await snip721Contract.getP5jsCode();
                const textToReplace = "<!-- FOR MINTING ONLY, DO NOT MODIFY -->";
                const getTokenInfoScript = `<script>function getTokenInfo(projectNumber) { return { answers: { "Color Palette": '${answers['Color Palette']}', "Number of Rings": ${parseFloat(answers['Number of Rings'])}, "Genesis Ring Points": ${parseFloat(answers['Genesis Ring Points'])} }, hash: '${seed}', tokenId: ${this.art.id}}}<\/script>`;
                    
                // Encode and save the token data and the code in local storage
                const html = this.fromBase64(code).replace(textToReplace, getTokenInfoScript)
    
                // Load the iframe with the generate-art.html page
                const iframe = document.getElementById('iframe');
                iframe.srcdoc = html;
                setTimeout(async () => {
                    const { getTokenInfo, calculateFeatures } = iframe.contentWindow;
                    const tokenInfo = getTokenInfo();
                    const result = calculateFeatures(tokenInfo);
                    const traits = Object.keys(result).map((key)=>({
                        name: key,
                        value: result[key]
                    }))
                    const saveTraitsCommand = {
                        artId: this.art.id,
                        traits,
                    };
                    await api.projects.arts.traits.saveTraits(this.project.id,this.art.id,saveTraitsCommand);
                }, 5000);
            } catch (error) {
                console.error(error)    
            }
        },
        fromBase64(str) {
            return decodeURIComponent(escape(window.atob(str)));
        },
        async saveTraits(){
            try {
                const traits = Object.keys(this.traits).map((key)=>({
                    name: key,
                    value: this.traits[key]
                }))
                const saveTraitsCommand = {
                    artId: this.art.id,
                    traits,
                };
                const res = await api.projects.arts.traits.saveTraits(this.project.id,this.art.id,saveTraitsCommand)
            } catch (error) {
                console.error(error)
            }
        },
        async getRealTraits(){
            try {
                const res = await api.projects.arts.traits.getArtTraits(this.project.id,this.art.id)
                this.realTraits = res;
            } catch (error) {
                console.error(error)
            }
        },
        isTraitValid(traitName){
            if(!this.traits || !this.realTraits || !traitName) return true;

            const currentTraitValue = this.getTraitValue(traitName);
            const realTrait = this.realTraits.find((e)=> e.name == traitName);
            
            if(!realTrait) return true;

            return realTrait.value == currentTraitValue;
        },
        async sendProvenance() {
            const snip721Contract = refContract(this.project.snip721ContractAddress);
            this.authorName = !this.initials;
            this.provenanceDateFlag = !this.provenanceDate;
            this.tokenPrice = !this.scrtPrice;
            if (this.authorName || this.provenanceDateFlag || this.tokenPrice ) {
                return;
            }
            this.formIsLoading = true;

            //12K is the constant aprox for each element in the list
            const addProvenanceFee = (this.provenanceList.length * 48000) + 100000;
            
            try {
              await snip721Contract.addProvenance(
                this.art.id,
                this.initials,
                moment(this.provenanceDate).valueOf(),
                this.scrtPrice,
                addProvenanceFee
              );
              this.showSuccessMessage = true;
              await this.getProvenanceRecords();
              this.changeShowProvenanceList();
            }catch(error) {
              console.error('Error', error);
              this.showErrorMessage = true;
            }finally {
              this.resetProvenanceForm();
              this.formIsLoading = false;
            }
        },
        resetProvenanceForm() {
          this.initials = '',
          this.provenanceDate = '',
          this.scrtPrice = ''
        },
        goToTraits (projectId, artId, attribute, attributeValue) {
            if (projectId == 2) return;
            if (!attributeValue) return; 

            let attributeFormated = formatTraitAttribute(attribute);

            this.$router.push({ 
                name: 'filtered-traits', 
                params: { 
                    projectId: projectId, 
                    artId: artId,
                    attribute: attributeFormated,
                    attributeValue: attributeValue,
                },
                query: { attributeTitle: attribute }, 
            });
        },
        setContentVisibility(value) {
            this.showDescription = value;
           /*  return value = !value; */
        }
    }
}
</script>

<style lang="scss" scoped>
:root{
    --vue-custom-tooltip-border-radius:10px;
}
.custom-tooltip{
    &::after{
        border-radius: 0px !important;
        font-family: Roboto Condensed !important;
        font-size: 16px !important;
        font-weight: 400 !important;
    }
    justify-content: center;
    display: flex !important;
    gap: 10px;
    cursor: pointer;
    border-radius: 0px;
    color: #D73333;
    span{
        text-decoration: line-through;
    }
}
#iframe {
  opacity: 0;
  display: none;
}
.nft-details {
    max-width: 1200px;
    margin: 42px auto;
    .box {
        padding: 16px;
    }
    .project {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 48px;
        &__left {
            .autor {
                display: flex;
                flex-direction: column;
                row-gap: 8px;
                h1, h4 {
                    margin: 0px;
                }
                h1 {
                    line-height: 46px;
                    font-style: italic;
                }
                h4 {
                    line-height: 24px;
                }
                .social-media {
                    display: flex;
                    gap: 8px;
                    align-items: center;
                    flex-wrap: wrap;
                    .link {
                        color: var(--color-emerald);
                        font-weight: 400;
                        text-decoration: none;
                    }
                }
            }
            .image-block-mobile {
                display: none;
                grid-auto-flow: column;
                .art {
                    .card {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        position: relative;
                        img {
                            max-width: 200px !important;
                            height: auto;
                            height: 544px;
                        }
                        .links {
                            width: 100%;
                            height: 24px;
                            margin-top: 16px;
                            display: flex;
                            justify-content: space-between;
                            background: transparent;
                            align-items: center;
                            a {
                                color: var(--color-emerald);
                                text-decoration: none;
                                text-transform: lowercase;
                                font-weight: 400;
                            }
                            &-left {
                                display: flex; 
                                gap: 16px;
                            }
                        }
                    }
                }
            }
            .links {
                margin-top: 26px;
                display: flex;
                gap: 16px;
                a {
                    color: var(--color-emerald);
                    font-weight: 400;
                    text-decoration: none;
                    line-height: 24px;
                }
            }
            .dates {
                grid-column: 1 / 2;
                margin-top: 26px;
                display: flex;
                flex-direction: column;
                gap: 16px;
                p {
                    font-weight: 700;
                    margin: 0px;
                }
                .date {
                    font-weight: 400;
                }
                a {
                    all: unset;
                    &.twitter-share-button {
                        padding: 6px 6px;
                        background-color: rgb(29, 155, 240);
                        color: var(--color-neutral-white);
                        border-radius: 12px;
                        width: 72px;
                        text-align: center;
                        cursor: pointer;
                        font-weight: bold;
                    }
                }
            }
            .description {
                grid-column: 1 / 2;
                margin: 26px 0px;
            }
            .traits {
                grid-column: 1 / 2;
                .title {
                font-weight: 700;
                }
                .labels{
                    font-family: Roboto Condensed;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    color:#808080;
    
                    &.warning {
                        background-color: var(--color-yellow);
                        padding: 10px;
                    }
                }
                .reveal-message {
                    display: grid;
                    grid-auto-flow: row;
                    grid-row-gap: 4px;
    
                    div {
                        line-height: 24px;
                    }
                }
                .label {
                    text-decoration: underline;
                    color: var(--color-emerald);
                    cursor: pointer;
                }
                .traits-table{
                    width: 100%;
                    border-collapse: collapse;
                    margin-top: 16px;
                    td {
                        padding: 8px 16px;
                        vertical-align: middle;
                    }
                    .center{
                        text-align: center;
                    }
                    .end {
                        text-align: end;
                    }
                    tbody {
                        tr {
                            border-bottom: 1px solid black;
                            p {
                                margin: 0px;
                            }
                            &:hover {
                                background-color: var(--color-emerald);
                                cursor: pointer;
                                color: var(--color-neutral-05);
                                p {
                                    color: var(--color-neutral-05);
                                }
                            }
                        }
                    }
                    &-description {
                        p {
                            color: var(--color-neutral-02);
                            line-height: 16px;
                            margin: 16px 0px 0px 0px;
                            font-size: 12px; 
                            text-justify: left;
                        }
                    }
                }
            }
        }
        &__right {
            position: sticky;
            align-self: start;
            top: 100px;
            display: flex;
            flex-direction: column;
            row-gap: 24px;
            .image-block-desktop {
                /* position: sticky;
                top: 100px; */
                .card {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    position: relative;
                    display: flex;

                    div {
                        //@include respond-to("large and up") {
                        //    display: flex;
                        //    align-items: center;
                        //    background-color: #cecece;
                        //    height: 544px;
                        //}
                        img {
                            width: 100%;
                        }
                    }
                    .links {
                        width: 100%;
                        height: 24px;
                        margin-top: 16px;
                        display: flex;
                        justify-content: space-between;
                        background: transparent;
                        align-items: center;
                        a {
                            color: var(--color-emerald);
                            text-decoration: none;
                            text-transform: lowercase;
                            font-weight: 400;
                            cursor: pointer;
                        }
                        &-left {
                            display: flex; 
                            gap: 16px;
                        }
                    }
                }
                
            }
            .provenance {
                grid-column: 2 / 3;
                .provenance-image {
                    margin-bottom: 26px;
                    .title {
                        font-weight: 700;
                    }
                    .image-input {
                        display: flex;
                        gap: 4px;
                        margin-top: 16px;
                        input {
                            width: 157px;
                            height: 46px;
                            padding: 11px 16px;
                            font-size: 16px;
                            margin: 0px;
                        }
                        a {
                            background-color: black;
                            border-radius: 8px;
                            margin: 0px;
                            cursor: pointer;
                            height: 46px;
                            padding: 11px 20px; 
                        }
                    }
                        span {
                        margin-bottom: 12px;
                        &.note {
                            color: var(--color-neutral-02);
                            size: 8px;
                        }
                    }
                }
                .provenance-records {
                    .success-message {
                        height: 44px;
                        display: block;
                        color: var(--color-neutral-black);
                        background-color: #C4E9C3;
                        padding: 15px 10px;
                        width: 96%;
                        margin: 16px 0px;
                    }
                    .title {
                        font-weight: 700;
                            & span {
                            font-weight: 400;
                            }
                        .label {
                            margin-left: 16px;
                            color: var(--color-emerald);
                            cursor: pointer;
                        }
                    }
                    .record {
                        .name {
                            .date, .price {
                                margin-left: 8px;
                                color: #808080;
                            }
                        }
                    }
                    .empty {
                        p {
                            color: #808080;
                            margin: 42px 0;
                        }
                    }
                }
                .provenance-inputs {
                    position: relative;
                    .error-message {
                        height: 44px;
                        display: block;
                        color: white;
                        background-color: #D73333;
                        padding: 15px 10px;
                        width: 96%;
                        margin-bottom: 16px;
                    }
                    .field {
                        margin-top: 16px;
                        label::after {
                            content: attr(data-end);
                            color: red ;
                        }
                        label {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 14px;
                            margin-bottom: 4px;
                        }
                    }
                    .fields-col {
                        display: flex;
                        justify-content: flex-start;
                        gap: 16px;
                        input {
                        width: 160px;
                        margin: 0px;
                        }
                    }
                    .required-message {
                        color: #D73333;
                        font-size: 12px;
                        line-height: 14px;
                        margin: 4px 0px 0px 0px;
                    }
                    .required {
                        border: 1px solid #D73333;
                    }
                    input {
                        border: 1px solid #000000;
                        box-sizing: border-box;
                        border-radius: 4px;
                        width: 336px;
                        height: 46px;
                        padding: 11px 16px; 
                        margin: 0px;
                    }
                    input[type=date] {
                        text-transform: uppercase;
                    }
                    input[type=date]::-webkit-calendar-picker-indicator {
                        background: url('../assets/icons/calendar.svg') no-repeat;  
                        position: absolute; left: -8px;
                        padding-right: 300px;
                    }
                    input::-webkit-datetime-edit { 
                        position: relative; left: 16px; 
                    }
                    input::-webkit-datetime-edit-fields-wrapper { 
                        position: relative; left: 16px; 
                    }
                    input[type=date]:invalid::-webkit-datetime-edit {
                        color: #808080;
                    }
                    button {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 24px;
                        padding: 11px 20px;
                        border-radius: 8px;
                        cursor: pointer;
                        margin: 16px 0px 0px 0px;
                    }
                }
            }
        }
    }
    @include respond-to("medium and down") {
        margin: 0px;
        .project__right {
            .provenance {
                display: none;
            }
            #iframe {
                display: none;
            }
        }
    }
    @include respond-to("small and down") {
        .project {
            grid-template-columns: unset;
            margin: 0px;
            row-gap: 0px;
            .image-block-mobile {
                margin-top: 26px;
                display: block;
                img {
                    width: 100%;
                }
            }
            .image-block-desktop {
                display: none;
            }
            .links {
                margin-top: 16px;
            }
            h3 {
                padding-top: 12px;
            }
        }
    }
}
</style>
